import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/home/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/seek',
    name: 'Seek',
    component: () => import('@/views/seek/index.vue')
  },
  {
    path: '/seek-details',
    name: 'SeekDetails',
    component: () => import('@/views/seek/details/index.vue')
  },
  {
    path: '/register-info',
    name: 'RegisterInfo',
    component: () => import('@/views/seek/register-info/index.vue'),
  },
  {
    path: '/explain',
    name: 'AudioExplain',
    component: () => import('@/views/explain/index.vue'),
  },
  {
    path: '/explain-details',
    name: 'ExplainDetails',
    component: () => import('@/views/explain/details/index.vue'),
  },
  // 活动列表
  {
    path: '/activity',
    name: 'Activity',
    component: () => import('@/views/activity/index.vue'),
  },
  // 活动详情
  {
    path: '/activity/details',
    name: 'ActivityDetails',
    component: () => import('@/views/activity/details/index.vue'),
  },
]

const router = new VueRouter({
  routes
})

export default router

<template>
  <van-popup v-model="visibility" closeable round position="bottom" :style="{ height: '80%' }" @close="handleClose">
    <div class="top-container">
      <div class="popup-title"></div>
    </div>

    <div class="details-container">
      <div class="audio-box">
        <div class="audio-cover">
          <img class="audio-cover__img" :src="spotInfo.picture">
          <div class="audio-control__icon">
            <img v-if="audioStatus === 'pause'" src="@/assets/yuyin.png" alt="播放" @click="handleAudioPlay" />
            <img v-else src="@/assets/pause.png" alt="暂停" @click="handleAudioPause" />
          </div>
        </div>
        <div class="audio-info">
          <div class="audio-name">{{ spotInfo.name }}</div>
          <div class="audio-progress">
            <div class="audio-progress__current">{{ currentTimeFormat }}</div>
            <div ref="progressBar" class="audio-progress__bar">
              <div class="audio-progress__scrollbar" :style="{ width: progressCurrentwidth + 'px' }"></div>
            </div>
            <div class="audio-progress__length">{{ durationFormat }}</div>
          </div>
        </div>
        <div class="audio-box__hidden">
          <audio v-if="spotInfo.id" ref="audio" controls>
            <source :src="spotInfo.voice" type="audio/mpeg">
            Your browser does not support this audio format.
          </audio>
        </div>
      </div>
      <div class="introduction">
        <div class="title">
          <span class="title-text">景点介绍</span>
        </div>
        <div style="text-indent: 2em;">{{ spotInfo.brief }}</div>
      </div>
    </div>
  </van-popup>
</template>

<script>
// import { getRouteList } from '@/api/index'

export default {
  name: 'SpotBriefPopup',
  props: {
    value: Boolean,
    spotInfo: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      visibility: false,
      // spotInfo: {},
      audioRef: null, // 播放器DOM
      progressBarWidth: '', // 进度条长度
      progressCurrentwidth: 0, // 进度条当前长度
      currentTime: 0, // 当前播放时长
      step: 0, // 进度条每秒步长
      timer: null,
      duration: 0, // 音频长度（秒）
      audioStatus: 'pause', // 播放器状态
    }
  },
  computed: {
    durationFormat() {
      const m = Math.floor(this.duration / 60)
      const s = (parseInt(this.duration % 60) + '').padEnd(2, 0)
      return `${m}:${s}`
    },
    currentTimeFormat() {
      const m = Math.floor(this.currentTime / 60)
      const s = (parseInt(this.currentTime % 60) + '').padStart(2, 0)
      return `${m}:${s}`
    }
  },
  watch: {
    value: {
      handler(val) {
        this.visibility = val
      },
      immediate: true
    },
    spotInfo: {
      handler(val) {
        this.initAudio()
      },
      deep: true
    }
  },
  methods: {
    // 初始化播放器
    initAudio() {
      this.$nextTick(() => {
        this.audioRef = this.$refs['audio']
        // ios上的浏览器不会预加载音频，所以无法触发canplay，需要先load
        // https://www.h5w3.com/140257.html
        this.audioRef.load()
        // 音频准备就绪
        this.audioRef.addEventListener('canplay', () => {
          // 获取音频长度
          this.duration = this.audioRef.duration
          // 进度条长度
          this.progressBarWidth = this.$refs['progressBar'].getBoundingClientRect().width
          this.step = this.progressBarWidth / this.duration
        })
      })
    },
    // 播放讲解音频
    handleAudioPlay() {
      if (!this.duration) return
      this.audioStatus = 'play'
      this.audioRef.play()
      this.timer = setInterval(() => {
        this.currentTime = Math.round(this.audioRef.currentTime)
        this.progressCurrentwidth = this.step * this.currentTime
        if (this.currentTime >= Math.round(this.audioRef.duration)) {
          this.handleAudioReset()
        }
      }, 1000)
    },
    // 暂停讲解音频
    handleAudioPause() {
      this.audioRef.pause()
      this.audioStatus = 'pause'
      clearInterval(this.timer)
      this.timer = null
    },
    // 重置播放器状态
    handleAudioReset() {
      this.audioRef.currentTime = 0
      this.progressCurrentwidth = 0
      this.currentTime = 0
      this.handleAudioPause()
    },
    handleClose() {
      this.handleAudioReset()
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/variable.scss';

.top-container {
  position: sticky;
  top: 0;
  height: $popup-header-height;
  border-bottom: 1px solid rgba($color: #aaaaaa, $alpha: .1);
  background: #ffffff;
  z-index: 1;

  .popup-title {
    height: 100%;
    line-height: $popup-header-height;
    font-size: 20px;
    text-align: left;
    text-indent: 1em;
  }
}

.details-container {
  height: calc(100% - $popup-header-height);
  padding: 10px 15px 0;
  text-align: left;
  box-sizing: border-box;
  overflow-y: auto;

  .audio-box {
    display: flex;
    align-items: center;

    .audio-cover {
      position: relative;
      width: 110px;
      height: 110px;
      border-radius: 50%;
      overflow: hidden;

      &__img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 5px;
      }

      .audio-control__icon {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 30px;
        height: 30px;
        padding: 5px;
        background-color: #ffffff;
        border-radius: 50%;
        transform: translate(-50%, -50%);

        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }

    .audio-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 110px;
      margin-left: 10px;

      .audio-name {
        margin-top: 20px;
        font-size: 20px;
        font-weight: bold;
      }

      .audio-progress {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        &__current {
          margin-right: 10px;
        }

        &__bar {
          flex: 1;
          position: relative;
          height: 3px;
          background-color: #e8e8e8;
          border-radius: 20px;

          .audio-progress__scrollbar {
            position: absolute;
            left: 0;
            top: 0;
            // width: 50%;
            height: 100%;
            background-color: #f9c432;

            &::after {
              content: '';
              position: absolute;
              top: 50%;
              right: -5px;
              width: 10px;
              height: 10px;
              transform: translateY(-50%);
              background-color: #ffffff;
              border: 1px solid #f9c432;
              border-radius: 50%;
            }
          }
        }

        &__length {
          margin-left: 10px;
        }
      }
    }

    &__hidden {
      width: 0;
      height: 0;
      overflow: hidden;
    }
  }


  .introduction {
    margin-top: 20px;

    .title {
      padding-left: 5px;
      padding-bottom: 10px;
      text-align: left;
      font-size: 18px;
      font-weight: bold;

      &-text {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          left: -5px;
          bottom: -2px;
          width: calc(100% + 10px);
          height: 8px;
          background-color: #fbdb82;
          z-index: -1;
        }
      }
    }
  }
}
</style>
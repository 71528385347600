import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
// import VConsole from 'vconsole'

import '@/assets/index.scss'
import '@/assets/leaflet.css'
import '@/assets/L.Control.Locate.min.css'

import { Button } from 'vant'
import { Tab, Tabs } from 'vant'
import { Popup } from 'vant'
import { Search } from 'vant'
import { Form } from 'vant'
import { Field, CellGroup } from 'vant'
import { RadioGroup, Radio } from 'vant'
import { Uploader } from 'vant'
import { Toast } from 'vant'
import { Checkbox, CheckboxGroup } from 'vant'
Vue.use(Button)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Popup)
Vue.use(Search)
Vue.use(Form)
Vue.use(Field)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Uploader)
Vue.use(Toast)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(CellGroup)

Vue.config.productionTip = false

moment.locale('zh-cn')
Vue.prototype.$moment = moment

// const vConsole = new VConsole()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
  <van-popup v-model="visibility" closeable position="bottom" :style="{ height: '100%' }" @close="handleClose">
    <div class="top-container">
      <div class="popup-title">景区概况</div>
    </div>

    <div class="content">
      <img :src="scenicInfo.mainPicture" alt="景区图片" />
      <div class="name-box">
        <div class="name-text">{{ scenicInfo.name }}</div>
        <div class="level">{{ scenicInfo.level }}</div>
      </div>
      <div class="info">
        <div class="title">
          <span class="title-text">景区信息</span>
        </div>
        <div class="info-item">
          <span class="info-item__label">开放时间：</span>
          <span class="info-item__text">{{scenicInfo.officeHours}}</span>
        </div>
        <div class="info-item">
          <span class="info-item__label">联系电话：</span>
          <span class="info-item__text" style="color: #fbdb82;">{{scenicInfo.telephone}}</span>
        </div>
        <div class="info-item">
          <span class="info-item__label">景区地址：</span>
          <span class="info-item__text">{{scenicInfo.address}}</span>
        </div>
      </div>
      <div class="introduction">
        <div class="title">
          <span class="title-text">景区简介</span>
        </div>
        <div v-html="scenicInfo.brief"></div>
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  name: 'OverviewPopup',
  props: {
    value: Boolean,
    scenicInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      visibility: false,
      info: {
        name: '阜阳生态乐园',
        level: '4A',
        img: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fwww.laomaozy.com%2Fupfiles%2Fimage%2F202111%2F20211127133944371.jpg&refer=http%3A%2F%2Fwww.laomaozy.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1669196423&t=674da92cabdfac2f74d161789cb1168e',
        openDate: '周一至周日 早8:00 - 晚18:00',
        phone: '1378909890',
        address: '安徽省阜阳市颖泉区欧阳修路666号',
        introduction: `<p style="text-indent: 2em;">阜阳生态园位于安徽省阜阳市颍泉区欧阳修路666号，是全国科普教育基地，全国林业科普基地，国家AAAA级旅游景区，全国农业旅游示范点，全国休闲农业与乡村旅游五星级园区、安徽省环境教育基地、全球网民心中安徽最美的地方。
          </p><p style="text-indent: 2em;">景区始建于2001年7月，2002年5月1日开园，是一家集农业观光、文化传承、生态旅游、休闲娱乐为一体的综合类旅游景区。</p><p style="text-indent: 2em;">景区有欧苏文化园、大型儿童乐园、高新农业技术园、热带植物园、精品果园、九和塔、竖琴广场、动物园、欧阳修故居会老堂等数十处主题景观组成，其底蕴厚重的历史文化、魅力精彩的现代农业、钟灵毓秀的园林美景、惊险刺激的主题乐园，使景区享誉江淮，成为皖北地区最具人气的旅游目的地。
          </p>`
      }
    }
  },
  watch: {
    value: {
      handler(val) {
        this.visibility = val
      },
      immediate: true
    }
  },
  methods: {
    handleClose() {
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/variable.scss';

.top-container {
  height: $popup-header-height;

  .popup-title {
    height: 100%;
    line-height: 56px;
    font-size: 20px;
  }
}

.content {
  height: calc(100% - $popup-header-height);
  padding: 10px;
  box-sizing: border-box;
  overflow: auto;
  text-align: left;

  img {
    display: block;
    width: 100%;
  }

  .name-box {
    display: flex;
    align-items: center;
    margin-top: 20px;

    .name-text {
      font-size: 26px;
      font-weight: bold;
    }

    .level {
      padding: 3px 20px;
      margin-left: 30px;
      font-size: 18px;
      font-weight: bold;
      background-color: #F9C432;
      border-radius: 15px;
    }
  }

  .info {
    margin-top: 20px;

    .info-item {
      margin-top: 10px;
      font-size: 14px;

      &__label {
        font-weight: bold;
      }
    }
  }

  .introduction {
    margin-top: 20px;

    ::v-deep img {
      width: 100%;
    }
  }

  .title {
    padding-left: 5px;
    padding-bottom: 10px;
    text-align: left;
    font-size: 18px;
    font-weight: bold;

    &-text {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        left: -5px;
        bottom: -2px;
        width: calc(100% + 10px);
        height: 8px;
        background-color: #fbdb82;
        z-index: -1;
      }
    }
  }
}
</style>
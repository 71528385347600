import axios from '@/utils/request'
import config from '@/config'

// 景区列表
export function getScenicList(params) {
  return axios({
    url: '/scenicmanage/tscenic/pageApp',
    params
  })
}

// 获取手绘地图
export function getMapByScenicId(params) {
  return axios({
    url: `/scenicmanage/tscenicpicture/getByScenicIdApp?scenicId=${config.scenicId}`,
    params
  })
}

// 景点类型列表
export function getScenicTypeList() {
  return axios({
    url: '/scenicmanage/tservicepointtype/pageApp?current=1&size=999',
    // url: '/scenicmanage/tservicepointtype/list'
  })
}

// 景点列表
export function getScenicMarkersList(params) {
  return axios({
    url: `/scenicmanage/tscenicspot/getByScenicIdApp?scenicId=${config.scenicId}`,
    // params
  })
}

// 服务点列表
export function getServiceMarkersList(params) {
  return axios({
    url: `/scenicmanage/tservicepoint/getByScenicIdApp?scenicId=${config.scenicId}`,
    // params
  })
}

// 推荐路线
export function getRouteList(params) {
  return axios({
    url: `/scenicmanage/tscenicroute/getRouteApp?scenicId=${config.scenicId}`,
    // params
  })
}

// 通过景区ID获取景点语音讲解列表（旧）
export function getAudioList(params) {
  return axios({
    url: `/scenicmanage/tscenicvoice/getByScenicIdApp?scenicId=${config.scenicId}`,
    // params
  })
}

// 通过景区ID获取景点语音讲解列表（新）
export function getAudioByScenicId(params) {
  return axios({
    url: `/scenicmanage/tscenicspotvoice/getByScenicIdApp?scenicId=${config.scenicId}`,
    params
  })
}

// 通过景点ID获取语音
export function getAudioByScenicSpotId(params) {
  return axios({
    url: '/scenicmanage/tscenicspotvoice/getByScenicSpotIdApp',
    params
  })
}

// 获取动物、植物信息（包含语音讲解）
export function getAnimalByScencId(params) {
  return axios({
    url: `/scenicmanage/tscenicanimal/getAnimalByScencId?scenicId=${config.scenicId}`,
    // params
  })
}

// 通过景区ID获取周边信息（旧）
// export function getPeripheryByScencicSpotId(params) {
//   return axios({
//     url: `/scenicmanage/tsurroundmessage/selectByScId?scenicId=${config.scenicId}`,
//     // params
//   })
// }

export function getPeripheryByScencicSpotId(params) {
  return axios({
    url: `/scenicmanage/tsurroundmessage/selectByScId?scenicId=${config.scenicId}`,
    // params
  })
}

// 通过景区ID获取活动信息
export function getActivityByScencicId(params) {
  return axios({
    url: `/scenicmanage/tactivity/getTActivityByScId?scenicId=${config.scenicId}`,
    // params
  })
}

// 通过景点ID获取景点活动信息
export function getSpotActivityByScencicSpotId(params) {
  return axios({
    url: '/scenicmanage/tactivity/selectBySpotId',
    params
  })
}

// 发送短信验证码
export function sendSMSValidateCode(mobile) {
  return axios({
    url: `/admin/sms/sendValidate/${mobile}`,
  })
}

// 验证短信验证码
export function verifySMSValidateCode({ mobile, code }) {
  return axios({
    url: `/admin/sms/validate/${mobile}/${code}`,
  })
}


<template>
  <van-popup v-model="visibility" closeable position="bottom" :style="{ height: '100%' }" @close="handleClose">
    <div class="top-container">
      <div class="popup-title">语音讲解</div>
    </div>

    <div class="scenic-list">
      <div v-for="item in list" class="scenic-item" :key="item.id">
        <div class="cover">
          <img :src="item.cover" alt="活动封面">
        </div>
        <div class="content">
          <div class="name">{{ item.name }}</div>
          <div class="detail">{{ item.detail }}</div>
        </div>
        <div class="audio">
          <div v-if="item.status === 'pause'" class="audio-box audio-play" @click="handleAudioPlay(item)">
            <img class="audio-box__icon" src="@/assets/yuyin.png" alt="播放">
          </div>
          <div v-else class="audio-box audio-pause" @click="handleAudioPause">
            <img class="audio-box__icon" src="@/assets/pause.png" alt="暂停">
          </div>
        </div>
      </div>
    </div>

    <div v-if="audioInfo" class="audio-container">
      <audio ref="audio" controls>
        <source :src="audioInfo.audio" type="audio/mpeg">
        Your browser does not support this audio format.
      </audio>
    </div>
  </van-popup>
</template>

<script>
export default {
  name: 'AudioExplainPopup',
  props: {
    value: Boolean,
    // list: {
    //   type: Array,
    //   default: () => []
    // }
  },
  data() {
    return {
      visibility: false,
      list: [
        { id: 0, name: '景点名称1', status: 'pause', detail: '介绍XXXXXXXXXXXXXXXXXXXXXXXXXXX', audio: 'https://m804.music.126.net/20221026175846/05f404c4259b174e502f4a549026717a/jdymusic/obj/wo3DlMOGwrbDjj7DisKw/14096498052/0021/b769/3920/01cd7b3391fb1375bf406135d10371d9.mp3?_=0000018413a400d907690aa4637ff0f5', cover: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202105%2F19%2F20210519212438_ced7e.jpg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1669003790&t=f7f40e7a7d4a8a24cd074d4215fabb71' },
        { id: 1, name: '景点名称2', status: 'pause', detail: '介绍XXXXXXXXXXXXXXXXXXXXXXXXXXX', audio: 'https://m804.music.126.net/20221026175846/05f404c4259b174e502f4a549026717a/jdymusic/obj/wo3DlMOGwrbDjj7DisKw/14096498052/0021/b769/3920/01cd7b3391fb1375bf406135d10371d9.mp3?_=0000018413a400d907690aa4637ff0f5', cover: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202105%2F19%2F20210519212438_ced7e.jpg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1669003790&t=f7f40e7a7d4a8a24cd074d4215fabb71' },
        { id: 2, name: '景点名称3', status: 'pause', detail: '介绍XXXXXXXXXXXXXXXXXXXXXXXXXXX', audio: 'https://m804.music.126.net/20221026175846/05f404c4259b174e502f4a549026717a/jdymusic/obj/wo3DlMOGwrbDjj7DisKw/14096498052/0021/b769/3920/01cd7b3391fb1375bf406135d10371d9.mp3?_=0000018413a400d907690aa4637ff0f5', cover: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202105%2F19%2F20210519212438_ced7e.jpg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1669003790&t=f7f40e7a7d4a8a24cd074d4215fabb71' },
        { id: 3, name: '景点名称4', status: 'pause', detail: '介绍XXXXXXXXXXXXXXXXXXXXXXXXXXX', audio: 'https://m804.music.126.net/20221026175846/05f404c4259b174e502f4a549026717a/jdymusic/obj/wo3DlMOGwrbDjj7DisKw/14096498052/0021/b769/3920/01cd7b3391fb1375bf406135d10371d9.mp3?_=0000018413a400d907690aa4637ff0f5', cover: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202105%2F19%2F20210519212438_ced7e.jpg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1669003790&t=f7f40e7a7d4a8a24cd074d4215fabb71' }
      ],
      audioInfo: null,
      audioRef: null
    }
  },
  watch: {
    value: {
      handler(val) {
        this.visibility = val
      },
      immediate: true
    }
  },
  methods: {
    handleClose() {
      this.$emit('input', false)
    },
    handleAudioPlay(val) {
      if (val !== this.audioInfo) {
        const initAudio = () => {
          this.audioInfo = val
          this.$nextTick(() => {
            this.audioRef = this.$refs['audio']
            // 播放器准备就绪，可以开始播放
            this.audioRef.addEventListener('canplay', () => {
              this.audioRef.play()
              this.audioInfo.status = 'play'
            })
          })
        }
        // 重置播放器状态
        if (this.audioInfo) {
          this.audioInfo.status = 'pause'
          this.audioInfo = null
          this.$nextTick(initAudio)
        } else {
          initAudio()
        }
      } else {
        this.audioRef.play()
        this.audioInfo.status = 'play'
      }
    },
    handleAudioPause() {
      this.audioRef.pause()
      this.audioInfo.status = 'pause'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/variable.scss';

.top-container {
  height: $popup-header-height;
}

.popup-title {
  height: 100%;
  line-height: $popup-header-height;
  font-size: 20px;
}

.scenic-list {
  padding: 0 15px;


  .scenic-item {
    display: flex;
    margin-bottom: 20px;


    .cover {
      width: 70px;
      height: 70px;
    }

    .cover img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
      margin-left: 10px;
      height: 70px;
      width: calc(100% - 130px);

      .detail {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .audio {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 70px;

      &-box {
        width: 30px;
        height: 30px;

        &__icon {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.audio-container {
  width: 0;
  height: 0;
  overflow: hidden;
}
</style>
<template>
  <van-popup v-model="visibility" closeable position="bottom" :style="{ height: '100%' }" @close="handleClose">
    <div class="top-container">
      <div class="popup-title">周边信息</div>
    </div>

    <div class="content">
      <div v-for="{ title, details, id } in list" class="info-item" :key="id">
        <div class="info-item__title">{{ title }}</div>
        <div v-for="(item, index) in details" class="info-item-detail" :key="index">
          <!-- <div class="info-item-detail__icon">{{ item.icon }}</div> -->
          <div class="info-item-detail__label">{{ item[0] }}：</div>
          <div class="info-item-detail__text">{{ item[1] }}</div>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { getPeripheryByScencicSpotId } from '@/api/index'

export default {
  name: 'PeripheryInfoPopup',
  props: {
    value: Boolean,
  },
  data() {
    return {
      visibility: false,
      list: []
    }
  },
  watch: {
    value: {
      handler(val) {
        this.visibility = val
      },
      immediate: true
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      getPeripheryByScencicSpotId().then(res => {
        this.list = res.map(item => {
          // 格式化
          item.details = item.message.split('\r\n')
            // 以冒号分隔
            .map(item => item.split('：'))
            // 去除前后空格
            .map(item => item.map(str => str.trim()))
          return item
        })
        console.log('[周边信息] ', this.list)
      })
    },
    handleClose() {
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/variable.scss';

.top-container {
  height: $popup-header-height;
}

.popup-title {
  height: 100%;
  line-height: 56px;
  font-size: 20px;
}

.content {
  height: calc(100% - $popup-header-height);
  padding: 5px 15px;
  box-sizing: border-box;
  overflow: auto;
  text-align: left;

  .info-item {
    margin-top: 10px;
    font-size: 14px;

    &__title {
      text-align: left;
      font-size: 18px;
      font-weight: bold;
    }

    &-detail {
      display: flex;
      align-items: center;
      margin-left: 15px;
      margin-top: 15px;
    }
  }
}
</style>
<template>
  <div class="home">
    <!-- 顶部导航栏 -->
    <div id="header">
      <div class="search-container" @click="handleSearchClick">
        <img src="@/assets/search.png" alt="搜索">
      </div>
      <van-tabs v-model="active" line-height="5px" color="#3f85e7" @change="handleTabChange">
        <template v-for="item in scenicTypeList">
          <van-tab v-if="!item.status" :title="item.name" :key="item.id"></van-tab>
        </template>
      </van-tabs>
    </div>

    <!-- 地图组件 -->
    <div class="map-container">
      <Map :markers="markers" :marker="marker" :route="route" @control-click="handleControlClick"
        @marker-click="handleMarkerClick" />
    </div>

    <!-- 底部导航栏 -->
    <div id="footer">
      <div class="tabbar">
        <div v-for="item of tabbars" class="tabbar-item" :key="item.value" @click="handleTabbarChange(item.value)">
          <div class="tabbar-item-icon">
            <img :src="item.icon" :alt="item.label" />
          </div>
          <div class="tabbar-item-label">
            {{ item.label }}
          </div>
        </div>
      </div>
    </div>

    <!-- 景区概况弹窗 -->
    <OverviewPopup v-model="overviewVisibility" :scenic-info="scenicInfo" />
    <!-- 推荐路线弹窗 -->
    <RoutePopup v-model="routeVisibility" @change="handleRouteChange" />
    <!-- 语音讲解弹窗 -->
    <AudioExplainPopup v-model="explainVisibility" />
    <!-- 动物展出弹窗 -->
    <AnimalShowPopup v-model="animalShowVisibility" :spot-list="markerList" @change="handleMarkerChange" />

    <!-- 搜索弹窗 -->
    <SearchPopup v-model="searchVisibility" :type-list="scenicTypeList" :scenic-spot-list="markerList"
      @change="handleMarkerChange" />
    <!-- 周边信息 -->
    <PeripheryInfoPopup v-model="peripheryVisibility" />
    <!-- 景点详情 -->
    <SpotBriefPopup v-model="spotVisibility" :spot-info="selectedSpotInfo" />
    <!-- 问卷调查 -->
    <FeedbackPopup v-model="feedbackVisibility" />
  </div>
</template>

<script>
import Map from '@/components/Map'
// 景区概况
import OverviewPopup from '@/components/OverviewPopup'
// 推荐路线
import RoutePopup from '@/components/RoutePopup'
// 语音讲解
import AudioExplainPopup from '@/components/AudioExplainPopup'
// 动物展出
import AnimalShowPopup from '@/components/AnimalShowPopup'
// 搜索景点
import SearchPopup from '@/components/SearchPopup'
// 寻人寻物
import PeripheryInfoPopup from '@/components/PeripheryInfoPopup'
// 问卷调查
import FeedbackPopup from '@/components/FeedbackPopup'
// 景点详情
import SpotBriefPopup from '@/components/SpotBriefPopup'

import { getScenicList, getScenicTypeList, getScenicMarkersList, getServiceMarkersList, getAudioByScenicSpotId } from '@/api/index'
import '@/utils/coordinateConvert.js'
import axios from 'axios'
import config from '@/config'

export default {
  name: 'Home',
  components: {
    Map,
    OverviewPopup,
    RoutePopup,
    AudioExplainPopup,
    AnimalShowPopup,
    SearchPopup,
    PeripheryInfoPopup,
    FeedbackPopup,
    SpotBriefPopup
  },
  props: {},
  data() {
    return {
      // 景区信息
      scenicInfo: {},
      // 底部导航栏列表
      tabbars: [
        { label: '景区概况', value: 0, icon: require('@/assets/tabbar_jingqu.png') },
        { label: '推荐路线', value: 1, icon: require('@/assets/tabbar_luxian.png') },
        { label: '语音讲解', value: 2, icon: require('@/assets/tabbar_yuyin.png') },
        { label: '景区活动', value: 3, icon: require('@/assets/tabbar_huodong.png') }
      ],
      active: 0,
      // 是否展示景区概况弹窗
      overviewVisibility: false,
      // 是否展示游览路线弹窗
      routeVisibility: false,
      // 是否展示语音讲解弹窗
      explainVisibility: false,
      // 是否展示动物展出弹窗
      animalShowVisibility: false,
      // 是否展示景区活动弹窗
      activityVisibility: false,
      // 是否展示搜索弹窗
      searchVisibility: false,
      // 是否展示周边信息弹窗
      peripheryVisibility: false,
      // 是否展示景点详情弹窗
      spotVisibility: false,
      selectedSpotInfo: {},
      // 是否展示问卷调查弹窗
      feedbackVisibility: false,
      // 展示的点位
      markers: [],
      // 定位的点位
      marker: {},
      // 景点分类
      scenicTypeList: [],
      // 景点列表
      markerList: [],
      // 推荐路线
      route: []
    }
  },
  created() {
    this.getScenicInfo()
    this.getScenicTypeList()
    // this.getScenicSpotList()
  },
  methods: {
    // 获取景区信息
    getScenicInfo() {
      getScenicList({
        current: 1,
        size: 10
      }).then(res => {
        this.scenicInfo = res.records.find(item => item.id === config.scenicId)
        console.log('[景区信息] ', this.scenicInfo)
      })
    },

    // 获取景点分类
    getScenicTypeList() {
      getScenicTypeList().then(res => {
        this.scenicTypeList = res.records.filter(item => !item.status)
        // this.scenicTypeList.unshift({
        //   id: 0,
        //   name: '全部'
        // })
        this.getScenicSpotList()
        console.log('[景点分类] ', this.scenicTypeList)
      })
    },

    // 获取景点和服务点列表
    getScenicSpotList() {
      const p = [getScenicMarkersList(), getServiceMarkersList()]
      Promise.all(p).then(res => {
        console.log('=====>', res)
        res[0].forEach(item => {
          item.isSpot = true
        })
        res = res.flat()
        res = res.map(item => {
          // 转换经纬度
          const latlng = CoordinateConvert.gcj02towgs84(item.longitude, item.latitude)
          item.longitude = latlng[0]
          item.latitude = latlng[1]
          const type = this.scenicTypeList.find(item2 => item2.id === item.typeId)
          item.markerIcon = type?.markerIcon || 'https://xytiles-xisland.oss-cn-hangzhou.aliyuncs.com/1530002364944-g9xj30ew.png'
          return item
        })
        this.markerList = res
        this.handleTabChange(0)
        console.log('[景点列表] ', res)
      }).catch(err => {
        console.log(err)
      })
    },

    // 切换景区点位
    handleTabChange(index) {
      const type = this.scenicTypeList[index]
      this.markers = this.markerList.filter(item => item.typeId === type.id)
      console.log('[当前点位] ', this.markers)
    },

    // 选择推荐路线
    handleRouteChange(val) {
      if (val) {
        const route = JSON.parse(val.route).map(item => {
          const point = CoordinateConvert.gcj02towgs84(item.longtitude, item.latitude)
          return point.reverse()
        })
        this.route = route
      } else {
        this.route = []
      }
    },

    // 选择底部tabbar
    handleTabbarChange(val) {
      if (val === 0) {
        this.overviewVisibility = true
      } else if (val === 1) {
        this.routeVisibility = true
      } else if (val === 2) {
        this.$router.push('/explain')
      } else {
        // this.activityVisibility = true
        this.$router.push('/activity')
      }
    },

    // 查看景点详情
    handleMarkerClick(spot) {
      console.log('[景点详情] ', spot)
      if (spot.brief) {
        this.selectedSpotInfo = spot
        this.spotVisibility = true
      }
      // getAudioByScenicSpotId({
      //   spotId: spot.id
      // }).then(res => {
      //   // 先通过有无音频数据判断是否是景点
      //   if (res.length) {
      //     this.$router.push(`/explain-details?id=${spot.id}`)
      //   }
      // })
    },

    // 处理自定义控件点击事件
    handleControlClick(id) {
      if (id === 0) {
        this.peripheryVisibility = true
      } else if (id === 1) {
        this.$router.push('/seek')
      } else if (id === 2) {
        const wx = require('weixin-js-sdk')
        const path = `/pages/openCustomerService/index?corpId=${this.scenicInfo.companyId}&url=${encodeURIComponent(this.scenicInfo.customerServiceUrl)}`
        wx.miniProgram.navigateTo({ url: path })
      } else if (id === 3) {
        window.location.href = 'https://www.wjx.cn/vm/mXrQ642.aspx#'
      } else if (id === 4) {
        this.animalShowVisibility = true
      }
    },

    // 搜索
    handleSearchClick() {
      this.searchVisibility = true
    },

    // 跳转到选择的点位
    handleMarkerChange(marker) {
      const findMarker = this.markerList.find(item => item.id === marker.id)
      const index = this.scenicTypeList.findIndex(item => item.id === findMarker.typeId)
      this.active = index
      this.handleTabChange(index)
      this.marker = marker
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#header {
  display: flex;

  .search-container {
    padding: 10px;

    img {
      display: block;
      width: 26px;
      height: 26px;
    }
  }

  .van-tabs {
    flex: 1;
    width: calc(100% - 46px);
  }
}

.map-container {
  flex: 1;
  background-color: rgb(155, 232, 205);
}

#footer {
  height: 50px;
}

.tabbar {
  display: flex;
  justify-content: space-around;
  height: 100%;
}

.tabbar-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;

  .tabbar-item-icon {
    img {
      display: block;
      width: 20px;
      height: 20px;
    }
  }

  .tabbar-item-label {}
}

.tabbar-item:nth-child(2)::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 1px;
  height: 50%;
  background-color: rgba($color: #484848, $alpha: .1);
  transform: translateY(-50%);
}

.tabbar-item:nth-child(2)::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 50%;
  background-color: rgba($color: #484848, $alpha: .1);
  transform: translateY(-50%);
}
</style>

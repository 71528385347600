<template>
  <van-popup v-model="visibility" closeable position="bottom" :style="{ height: '40%' }" @close="handleClose">
    <div class="top-container">
      <div class="popup-title">推荐路线</div>
    </div>

    <div class="route-list">
      <div v-for="item in routeList" :key="item.id" class="route-item">
        <div class="route-name">{{ item.name }}</div>
        <div class="route-checkbox">
          <van-checkbox v-model="item.checked" @change="handleRouteChange(item, $event)"></van-checkbox>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { getRouteList } from '@/api/index'

export default {
  name: 'RoutePopup',
  props: {
    value: Boolean
  },
  data() {
    return {
      visibility: false,
      routeList: [],
      checkedRoute: {}
    }
  },
  watch: {
    value: {
      handler(val) {
        this.visibility = val
      },
      immediate: true
    }
  },
  created() {
    this.getRouteList()
  },
  methods: {
    // 获取推荐路线
    getRouteList() {
      getRouteList().then(res => {
        res.forEach(item => item.checked = false)
        this.routeList = res
        console.log('[推荐路线] ', this.routeList)
      })

      // const res = getRouteList.data
    },

    // 选择推荐路线
    handleRouteChange(route, val) {
      if (val) {
        this.checkedRoute = route
        this.routeList.forEach(item => {
          item.checked = item === route
        })
        this.$emit('change', route)
      } else if (route === this.checkedRoute) {
        this.checkedRoute = {}
        this.$emit('change', undefined)
      }
      this.visibility = false
      this.handleClose()
    },

    handleClose() {
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/variable.scss';

.top-container {
  height: $popup-header-height;
  border-bottom: 1px solid rgba($color: #aaaaaa, $alpha: .1);

  .popup-title {
    height: 100%;
    line-height: $popup-header-height;
    font-size: 20px;
    text-align: left;
    text-indent: 1em;
  }
}

.route-list {
  padding: 10px 20px;

  .route-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .route-name {}

    .route-checkbox {}
  }
}
</style>
<template>
  <van-popup v-model="visibility" closeable position="bottom" :style="{ height: '100%' }" @close="handleClose">
    <div class="top-container">
      <div class="popup-title">动物展出</div>
    </div>

    <div class="animal-list">
      <template v-for="item in list">
        <div v-if="item.type === 1" class="animal-item" :key="item.id" @click="handleClickItem(item)">
          <div class="cover">
            <img :src="item.picture" alt="活动封面">
          </div>
          <div class="content">
            <div class="content-item flex">
              <div class="content-item__name">{{ item.animalName }}</div>
              <div class="content-item__status" :style="{ backgroundColor: item.openStatus ? '#bebdc0' : '#f9c432' }">
                {{ item.openStatus ? '今日不展出' : '今日展出' }}
              </div>
            </div>
            <div class="content-item">
              <div class="content-item__time">{{ item.startTime | formatDateTime(item.endTime) }}</div>
            </div>
            <div class="content-item">
              <div class="content-item__details">{{ item.remarks }}</div>
            </div>
            <!-- <div class="detail">
              <div class="name">{{ item.animalName }}</div>
              <div class="remark">{{ item.remarks }}</div>
            </div>
            <div class="status" :style="{ backgroundColor: item.status ? '#bebdc0' : '#f9c432' }">
              {{ item.status ? '未展出' : '展出' }}
            </div> -->
          </div>
        </div>
      </template>
    </div>
  </van-popup>
</template>

<script>
import { getAnimalByScencId } from '@/api/index'
export default {
  name: 'AnimalShowPopup',
  props: {
    value: Boolean,
    spotList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      visibility: false,
      list: []
    }
  },
  filters: {
    formatDateTime(startTime, endTime) {
      if (!startTime) return ''
      if (!endTime) return ''

      const reg = /[1-9]\d{3}-(((0[13578]|1[02])-(0[1-9]|[12][0-9]|3[01]))|((0[469]|11)-(0[1-9]|[12][0-9]|30))|(02-(0[1-9]|[1][0-9]|2[0-9]))) ([0-1][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]/
      if (reg.test(startTime) && reg.test(endTime)) {
        // ios不支持  yyyy-mm-ss 这种格式
        let st = new Date(startTime.replaceAll('-', '/'))
        let ed = new Date(endTime.replaceAll('-', '/'))
        st = st.getHours() + ':' + (st.getMinutes() + '').padStart(2, 0)
        ed = ed.getHours() + ':' + (ed.getMinutes() + '').padStart(2, 0)
        return `${st}-${ed}`
      }

      return ''
    },
  },
  watch: {
    value: {
      handler(val) {
        this.visibility = val
      },
      immediate: true
    }
  },
  created() {
    this.getAnimalList()
  },
  methods: {
    getAnimalList() {
      getAnimalByScencId().then(res => {
        this.list = res
        console.log('[动物列表] ', res)
      })
    },
    // 跳转到动物所在的地图点位
    handleClickItem(animal) {
      const marker = this.spotList.find(item => item.id === animal.spotId)
      this.$emit('change', marker)
      this.visibility = false
      this.handleClose()
    },
    handleClose() {
      this.$emit('input', false)
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/variable.scss';

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top-container {
  height: $popup-header-height;
  border-bottom: 1px solid rgba($color: #aaaaaa, $alpha: .1);

  .popup-title {
    height: 100%;
    line-height: 56px;
    font-size: 20px;
  }
}

.animal-list {
  padding: 0 15px;

  .animal-item {
    display: flex;
    margin-bottom: 20px;

    .cover {
      width: 100px;
      height: 100px;

      img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 5px;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: calc(100% - 110px);
      height: 100px;
      margin-left: 10px;
      text-align: left;

      .content-item {

        .content-item__name {
          font-weight: 600;
        }

        .content-item__status {
          padding: 2px 5px;
          color: #ffffff;
          font-size: 12px;
          border-radius: 3px;
        }

        .content-item__time {
          font-size: 12px;
        }

        .content-item__details {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          font-size: 12px;
        }
      }

      .detail {
        width: calc(100% - 80px);
        text-align: left;

        .name {
          font-size: 16px;
          font-weight: bold;
        }

        .remark {
          width: 100%;
          margin-top: 20px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    .status {
      width: 80px;
      padding: 7px 0;
      color: #ffffff;
      border-radius: 20px;
    }
  }
}
</style>
import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? '' : process.env.VUE_APP_BASE_API,
  timeout: 5000
})

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
  return config
}, function (error) {
  return Promise.reject(error)
})

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
  const res = response.data
  if (res.code === 0 || res.code === 200) {
    return res.data
  } else {
    return Promise.reject(new Error(res.msg || 'Error'))
  }
}, function (error) {
  return Promise.reject(error)
})

export default instance
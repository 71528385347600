<template>
  <van-popup v-model="visibility" closeable position="bottom" :style="{ height: '100%' }" @close="handleClose">
    <div class="top-container">
      <div class="popup-title">问卷调查</div>
    </div>
  </van-popup>
</template>

<script>
export default {
  name: 'FeedbackPopup',
  props: {
    value: Boolean,
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      visibility: false
    }
  },
  watch: {
    value: {
      handler(val) {
        this.visibility = val
      },
      immediate: true
    }
  },
  methods: {
    handleClose() {
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.top-container {
  height: 56px;
}

.popup-title {
  height: 100%;
  line-height: 56px;
  font-size: 20px;
}
</style>
<template>
  <van-popup v-model="visibility" closeable position="left" :style="{ height: '100%', width: '100%' }"
    @close="handleClose">
    <div class="top-container">
      <van-search v-model.trim="keyword" autofocus="true" placeholder="请输入搜索关键词" @search="handleSearch"
        @clear="handleReset" />
      <div class="search-btn" @click="handleSearch">
        <span class="search-btn__text">搜索</span>
      </div>
    </div>

    <van-tabs v-model="active" line-height="5px" color="#3f85e7" @change="handleTabChange">
      <van-tab v-for="item of typeList" :title="item.name" :key="item.id"></van-tab>
    </van-tabs>
    <div class="activity-list">
      <div v-for="item in markers" class="activity-item" :key="item.id" @click="handleItemChange(item)">
        <div class="cover">
          <img :src="item.picture || defaultImg" alt="景点图片">
        </div>
        <div class="content">
          <div class="name">{{ item.name }}</div>
          <div class="brief">{{ item.brief }}</div>
        </div>
      </div>
      <div v-if="!markers.length" class="not_found">
        <img src="@/assets/not_found.png" alt="无数据">
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  name: 'SearchPopup',
  props: {
    value: Boolean,
    // 景点类型
    typeList: {
      type: Array,
      default: () => []
    },
    // 景点列表
    scenicSpotList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      visibility: false,
      keyword: '',
      active: 0,
      markers: [],
      defaultImg: 'https://xisland-apex.oss-cn-hangzhou.aliyuncs.com/202211021667382400232.png'
    }
  },
  watch: {
    value: {
      handler(val) {
        this.visibility = val
        if (val === false) {
          this.handleReset()
        }
      },
      immediate: true
    },
    typeList() {
      this.handleTabChange(0)
    },
    scenicSpotList() {
      this.handleTabChange(0)
    }
  },
  created() {
    this.handleTabChange(0)
  },
  methods: {
    handleSearch() {
      if (this.keyword) {
        const res = this.scenicSpotList.filter(item => item.name.includes(this.keyword))
        if (res.length) {
          this.active = this.typeList.findIndex(item => item.id === res[0].typeId)
        }
        this.markers = res
      } else {
        this.handleReset()
      }
    },
    handleTabChange(index) {
      if (!this.typeList.length) return
      if (!this.scenicSpotList.length) return

      const type = this.typeList[index]
      this.markers = this.scenicSpotList.filter(item => item.typeId === type.id)
    },
    handleItemChange(item) {
      this.$emit('change', item)
      this.visibility = false
      this.handleClose()
    },
    // 重置状态
    handleReset() {
      this.keyword = ''
      this.active = 0
      this.handleTabChange(0)
    },
    handleClose() {
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/variable.scss';

.top-container {
  display: flex;
  align-items: center;
  height: $popup-header-height;

  .van-search {
    width: 290px;
  }

  .search-btn {
    color: #5288e3;
    font-size: 16px;
    font-weight: bold;
  }
}

.activity-list {
  padding: 10px 15px 0;

  .activity-item {
    display: flex;
    margin-bottom: 20px;

    .cover {
      width: 70px;
      height: 70px;

      img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 5px;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
      margin-left: 10px;
      height: 70px;
      width: calc(100% - 80px);

      .name {
        color: #f9c432;
      }

      .brief {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
      }
    }
  }
}

.not_found {

  img {
    margin-top: 40%;
    width: 100%;
  }
}
</style>
<template>
  <div id="map">
  </div>
</template>

<script>
import '@/utils/leaflet.js'
import '@/utils/proj4.js'
// 高德地图瓦片
import '@/utils/leaflet.ChineseTmsProviders.js'
// import '@/utils/leaflet.ChineseTmsProviders2.js'
// 高德地图纠偏插件
import '@/utils/leaflet.mapCorrection.min.js'
// 定位插件
import '@/utils/L.Control.Locate.min.js'
// 坐标转换（使用大地坐标系（WGS-84））
import '@/utils/coordinateConvert.js'

export default {
  name: 'Map',
  props: {
    // 地图点位
    markers: {
      type: Array,
      default: () => []
    },
    // 路线
    route: {
      type: Array,
      default: () => []
    },
    // 选择的点位
    marker: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      // 地图实例
      map: null,
      // 基础图层
      layers: [],
      // 点位图层
      markersLayer: [],
      // 路线图层
      polylineLayer: null,
      // 折线点位
      polylineMarkers: [],
      // 自定义控件列表
      controls: [
        { id: 0, name: 'zhoubian', label: '周边', icon: require('@/assets/zhoubian.png') },
        { id: 1, name: 'fuwu', label: '服务', icon: require('@/assets/fuwu.png') },
        { id: 2, name: 'kefu', label: '客服', icon: require('@/assets/kefu.png') },
        { id: 3, name: 'wenjuan', label: '反馈', icon: require('@/assets/wenjuan.png') },
        { id: 4, name: 'huodong', label: '', icon: require('@/assets/dongwu.png') },
      ]
    }
  },
  watch: {
    markers: {
      handler(val) {
        this.removeMarkers()
        this.addMarkers(val)
      },
      immediate: true
    },
    route(val) {
      this.removePolyLine()
      this.addPolyLine()
    },
    marker() {
      this.handleMoveToMarker()
    },
  },
  mounted() {
    this.initMap()
  },
  methods: {
    // 初始化地图
    initMap() {
      // 普通地图
      const normalm = L.tileLayer.chinaProvider('GaoDe.Normal.Map', {
        maxZoom: 18,
        minZoom: 5
      })
      // 卫星影像
      const imgm = L.tileLayer.chinaProvider('GaoDe.Satellite.Map', {
        maxZoom: 18,
        minZoom: 5
      })
      const imga = L.tileLayer.chinaProvider('GaoDe.Satellite.Annotion', {
        maxZoom: 18,
        minZoom: 5
      })
      const normal = L.layerGroup([normalm])
      const image = L.layerGroup([imgm, imga])
      this.layers.push(normal)
      this.layers.push(image)

      // 构建视图限制范围
      const boundsTopLeft = L.latLng(30.893981424354704, 122.38996179204474) // 设置左上角经纬度
      const boundsBottomRight = L.latLng(30.640918745239988, 122.86462494825014) // 设置右下点经纬度
      const bounds = L.latLngBounds(boundsTopLeft, boundsBottomRight)

      // 初始化地图
      this.map = L.map('map', {
        center: [31.688902675518204, 117.12969262896043], // 初始化地图中心点
        zoom: 15, // 缩放层级
        // minZoom: 12, // 最小层级
        layers: [normal], // 默认地图瓦片
        zoomControl: false, // 缩放控件，默认在左上角，这里设置为false，后面添加后更改位置
        // maxBounds: bounds, // 地图可视范围（拖动范围）
        attributionControl: false, // 是否展示右下角版权控件
      })
      // 地图点击事件
      this.map.on('click', this.handleMapClick)
      this.addControl()
      this.addMarkers(this.markers)
      this.addOverlayLayers()
    },

    // 添加地图控件
    addControl() {
      if (!this.map) return

      // 定位控件
      L.control.locate().setPosition('bottomleft').addTo(this.map)
      // L.geolet({ position: 'bottomleft', className: 'test', html: '<svg t="1652416015220" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1988" style="padding:2px;"><path d="M469.333333 850.688A341.504 341.504 0 0 1 173.312 554.666667H106.666667a21.333333 21.333333 0 0 1-21.333334-21.333334v-42.666666a21.333333 21.333333 0 0 1 21.333334-21.333334h66.645333A341.504 341.504 0 0 1 469.333333 173.312V106.666667a21.333333 21.333333 0 0 1 21.333334-21.333334h42.666666a21.333333 21.333333 0 0 1 21.333334 21.333334v66.645333A341.504 341.504 0 0 1 850.688 469.333333H917.333333a21.333333 21.333333 0 0 1 21.333334 21.333334v42.666666a21.333333 21.333333 0 0 1-21.333334 21.333334h-66.645333A341.504 341.504 0 0 1 554.666667 850.688V917.333333a21.333333 21.333333 0 0 1-21.333334 21.333334h-42.666666a21.333333 21.333333 0 0 1-21.333334-21.333334v-66.645333zM512 768a256 256 0 1 0 0-512 256 256 0 0 0 0 512z m0-128a128 128 0 1 1 0-256 128 128 0 0 1 0 256z" p-id="1989" fill="currentColor"></path></svg>' }).addTo(this.map)
      // 添加自定义控件
      const _this = this
      this.controls.forEach(item => {
        L.Control[item.name] = L.Control.extend({
          options: {
            position: 'bottomright' //初始位置
          },
          initialize: function (options) {
            L.Util.extend(this.options, options)
          },
          onAdd: function (map) {
            this._container = L.DomUtil.create('div', `leaflet-control-container ${item.id === 4 ? 'leaflet-control-activity' : ''}`)
            this._container.innerHTML = `
              <img src="${item.icon}">
              <div style="font-size:3vw;">${item.label}</div>
            `

            // 注册点击事件
            L.DomEvent.addListener(this._container, 'click', function () {
              _this.$emit('control-click', item.id)
            }, this)

            return this._container
          }
        })
        L.control[item.name] = function (options) {
          return new L.Control[item.name](options)
        }
        if (item.id === 4) {
          L.control[item.name]({ position: 'topleft' }).addTo(this.map)
        } else {
          L.control[item.name]().addTo(this.map)
        }
      })
    },

    // 添加自定义图层
    addOverlayLayers() {
      if (!this.map) return
      // 以图片方式加载单个图层，优点是方便，缺点是卡顿、体验差
      // const imageUrl = require('@/assets/map.jpg')
      // const overlayTopLeft = [32.93857159233585, 115.77190470307936]
      // const overlayBottomRight = [32.92707654756938, 115.79082451379266]
      // const imageBounds = [overlayTopLeft, overlayBottomRight]
      // L.imageOverlay(imageUrl, imageBounds).addTo(this.map)

      // 获取手绘图中心坐标点
      // this.map.fitBounds(imageBounds)
      // setTimeout(() => {
      //   console.log(this.map.getCenter())
      // }, 5000)

      // 以瓦片形式加载图层，优点是加载速度快，体验好，缺点是配置麻烦，软件收费
      L.TileLayer.T = L.TileLayer.extend({
        getTileUrl: function (c) {
          let path = ''
          try {
            path = require(`@/assets/tiles/${c.z}/${c.x}_${c.y}.png`)
          } catch (e) {
            path = `@/assets/tiles/${c.z}/${c.x}_${c.y}.png`
          }
          return path
        }
      })
      // 经测试 zoom 要写这里才有效
      const tileM = new L.TileLayer.T('', { minZoom: 5, maxZoom: 18, zIndex: 999999 })
      this.map.addLayer(tileM)
    },

    // 批量添加标记点
    addMarkers(markers, iconSize = { width: 28, height: 36 }) {
      if (!this.map) return
      if (!markers.length) return

      markers.forEach(item => {
        if (this.markersLayer.find(m => m.id === item.id)) return
        const myIcon = L.icon({
          iconUrl: item.markerIcon,
          iconSize: [iconSize.width, iconSize.height],
          iconAnchor: [iconSize.width / 2, iconSize.height]
        })

        // 设置信息弹窗
        // const popupDiv = document.createElement('div')
        // popupDiv.innerText = 'testtest'
        // popupDiv.onclick = () => this.handlePopupClick(item)

        // openStatus: 0-营业  1-未营业  2-不展示
        let openStatus = ''
        const isSpotType = process.env.VUE_APP_SPOT_TYPEID === item.typeId
        if (item.openStatus !== 2) {
          const openDateTime = this.formatDateTime(item.startTime, item.endTime) // 营业时间
          // 营业 or 开放 状态
          openStatus = item.openStatus
            ? `(${isSpotType ? '不开放' : '未营业'})`
            : `(${isSpotType ? '开放' : '营业'}${openDateTime ? ' ' + openDateTime : ''})`
        }

        const marker = L.marker([item.latitude, item.longitude], { icon: myIcon })
          .addTo(this.map)
          // .bindPopup(popupDiv)
          // 设置标点名称，interactive 如果为 true，popup/tooltip 将监听鼠标事件
          .bindTooltip(item.name + openStatus, { direction: 'bottom', permanent: true, interactive: true })
          .openTooltip()
          .on('click', () => {
            this.$emit('marker-click', item)
          })
        this.markersLayer.push({ id: item.id, marker: marker })
      })
    },

    // 批量移除标记点
    removeMarkers() {
      this.markersLayer.forEach(item => item.marker.remove())
      this.markersLayer = []
    },

    // 添加折线点
    addPolyLine() {
      if (!this.route.length) return
      this.polylineMarkers = this.route.map((item, index) => {
        const myIcon = L.divIcon({ html: index, className: 'polyline-icon' })
        return L.marker(item, { icon: myIcon }).addTo(this.map)
      })
      this.polylineLayer = L.polyline(this.route, {
        color: '#14c768', weight: 6
      }).addTo(this.map)
    },

    // 移除折线点
    removePolyLine() {
      this.polylineMarkers.forEach(item => item.remove())
      this.polylineMarkers = []
      this.polylineLayer && this.polylineLayer.remove()
    },

    // 地图点击事件
    handleMapClick(e) {
      // const hasPosition = IsPtInPoly(e.latlng.lng, e.latlng.lat, points)
      // alert(hasPosition)

      // 判断某个点是否在一个给定的区域内（地理围栏）
      // const latlngs = points.map(p => L.latLng(p.lat, p.lng))
      // const bounds2 = L.latLngBounds(latlngs)
      // const p = CoordinateConvert.wgs84togcj02(e.latlng.lng, e.latlng.lat)
      // const hasPosition = bounds2.contains(L.latLng(p[1], p[0]))
      // alert(hasPosition)

      // console.log(e)
    },

    // 获取当前定位
    getUserLocation() {
      this.map.locate({
        // watch: true, // 连续监听定位，而不是只检测一次
        enableHighAccuracy: true, // 启用高精度定位
      })
      map.on('locationfound', this.handleLocationFound)
      map.on('locationerror', this.handleLocationError)
    },

    // 获取定位成功
    handleLocationFound(e) {
      console.log('[获取用户定位成功：] ', e)
      L.marker([e.latitude, e.longitude]).addTo(this.map).bindPopup("当前位置")
      map.setView([e.latitude, e.longitude], 13)
    },

    // 获取定位失败
    handleLocationError(e) {
      console.log('[获取用户定位失败：] ', e)
    },

    // 地图标记点弹窗
    handleMarkerClick(e) {
      this.$emit('marker-click', e)
      // this.markersLayer.find(item => item.id === e.id).marker.closePopup()
    },

    // 移动到选择的点位
    handleMoveToMarker() {
      this.markersLayer.find(item => item.id === this.marker.id).marker.remove()
      this.markersLayer = this.markersLayer.filter(item => item.id !== this.marker.id)
      this.addMarkers([this.marker], { width: 44, height: 56 })
      this.map.flyTo([this.marker.latitude, this.marker.longitude], 18, { duration: 0.8 })
    },

    // 格式化营业时间
    formatDateTime(startTime, endTime) {
      if (!startTime) return ''
      if (!endTime) return ''

      // toLocaleString在不同的平台结果会不一样
      // const hasDate = (date) => date.toLocaleString() !== '1970/1/1 08:00:00'
      // const hasDate = (date) => date.toString() !== new Date(null).toString()
      // const isString = (val) => Object.prototype.toString.call(val) === '[object String]'

      const reg = /[1-9]\d{3}-(((0[13578]|1[02])-(0[1-9]|[12][0-9]|3[01]))|((0[469]|11)-(0[1-9]|[12][0-9]|30))|(02-(0[1-9]|[1][0-9]|2[0-9]))) ([0-1][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]/
      if (reg.test(startTime) && reg.test(endTime)) {
        // ios不支持  yyyy-mm-ss 这种格式
        let st = new Date(startTime.replaceAll('-', '/'))
        let ed = new Date(endTime.replaceAll('-', '/'))
        st = st.getHours() + ':' + (st.getMinutes() + '').padStart(2, 0)
        ed = ed.getHours() + ':' + (ed.getMinutes() + '').padStart(2, 0)
        return `${st}-${ed}`
      }

      return ''
    },
  }
}
</script>

<style lang="scss" scoped>
#map {
  width: 100%;
  height: 100%;
}

// .my-div-icon {
//   width: auto !important;
//   height: auto !important;
//   white-space: nowrap;
// }

// 强制使用自定义宽高，否则在不同设备会出现偏移问题
::v-deep .polyline-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -10px !important;
  margin-top: -10px !important;
  padding: 4px;
  width: 12px !important;
  height: 12px !important;
  font-size: 12px;
  color: #ffffff;
  background-color: #14c768;
  border-radius: 50%;
}

.marker-icon-box,
.marker-text-box {
  padding: 2px 10px;
  background-color: #FFFFFF;
  border-radius: 100px;
}

.marker-icon-box {
  margin-top: 5px;
}

::v-deep .leaflet-control-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 12vw;
  height: 12vw;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0 0 3vw 0.1vw rgb(0 0 0 / 10%);

  img {
    display: block;
    width: 5vw;
    height: 5vw;
  }
}

::v-deep .leaflet-control-container.leaflet-control-activity {
  border-radius: 50%;
  background-color: #ffffff;

  img {
    display: block;
    width: 7vw;
    height: 7vw;
  }
}

::v-deep .leaflet-tooltip {
  padding: 0 6px;
  line-height: 1.4;
  // border: 1px solid #434343;
}
</style>
